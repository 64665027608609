export default [
  'Ackbar',
  'Adi Gallia',
  'Anakin Skywalker',
  'Arvel Crynyd',
  'Ayla Secura',
  'Bail Prestor Organa',
  'Barriss Offee',
  'Ben Quadinaros',
  'Beru Whitesun lars',
  'Bib Fortuna',
  'Biggs Darklighter',
  'Boba Fett',
  'Bossk',
  'C-3PO',
  'Chewbacca',
  'Cliegg Lars',
  'Cordé',
  'Darth Maul',
  'Darth Vader',
  'Dexter Jettster',
  'Dooku',
  'Dormé',
  'Dud Bolt',
  'Eeth Koth',
  'Finis Valorum',
  'Gasgano',
  'Greedo',
  'Gregar Typho',
  'Grievous',
  'Han Solo',
  'IG-88',
  'Jabba Desilijic Tiure',
  'Jango Fett',
  'Jar Jar Binks',
  'Jek Tono Porkins',
  'Jocasta Nu',
  'Ki-Adi-Mundi',
  'Kit Fisto',
  'Lama Su',
  'Lando Calrissian',
  'Leia Organa',
  'Lobot',
  'Luke Skywalker',
  'Luminara Unduli',
  'Mace Windu',
  'Mas Amedda',
  'Mon Mothma',
  'Nien Nunb',
  'Nute Gunray',
  'Obi-Wan Kenobi',
  'Owen Lars',
  'Padmé Amidala',
  'Palpatine',
  'Plo Koon',
  'Poggle the Lesser',
  'Quarsh Panaka',
  'Qui-Gon Jinn',
  'R2-D2',
  'R4-P17',
  'R5-D4',
  'Ratts Tyerel',
  'Raymus Antilles',
  'Ric Olié',
  'Roos Tarpals',
  'Rugor Nass',
  'Saesee Tiin',
  'San Hill',
  'Sebulba',
  'Shaak Ti',
  'Shmi Skywalker',
  'Sly Moore',
  'Tarfful',
  'Taun We',
  'Tion Medon',
  'Wat Tambor',
  'Watto',
  'Wedge Antilles',
  'Wicket Systri Warrick',
  'Wilhuff Tarkin',
  'Yarael Poof',
  'Yoda',
  'Zam Wesell',
];
